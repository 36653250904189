import { tabsEventKey } from 'types/ETabKeys';

export const getBrowNavItemLink = (path: string, eventKey: string, isPdp: boolean) => {
  const pathArray = path.split('?');
  const hasQueryString = pathArray.length > 1;

  if (hasQueryString && eventKey === tabsEventKey.OVERVIEW) {
    pathArray[1].replace(`#${eventKey}`, '');
    return isPdp ? `?${pathArray[1]}#` : `${pathArray[0]}/overview?${pathArray[1]}`;
  }

  if (hasQueryString) {
    return isPdp ? `?${pathArray[1]}#${eventKey}` : `${pathArray[0]}/overview?${pathArray[1]}#${eventKey}`;
  }

  if (eventKey === tabsEventKey.OVERVIEW) {
    return isPdp ? '#' : `${path}/overview`;
  }

  return isPdp ? `#${eventKey}` : `${path}/overview#${eventKey}`;
};
