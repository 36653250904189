import { BrowDTO } from './BrowDTO';
import { CtProductIDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { FootNoteDTO } from './FootNoteDTO';
import { FullWidthTextDTO } from './FullWidthTextDTO';
import { HeaderDTO } from './HeaderDTO';
import { OneToFourColumnDTO } from './OneToFourColumnDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { Product } from '../../types/product/Product';
import {
  IBuyNowCTAButton,
  IPopAccessoriesTab,
  IPopBuyNowContent,
  IPopCompatibleWith,
  IPopProductForumTab,
  IPopRatingsReviews,
  IPopSupportTabBlock,
  IPopSupportTabProductManual,
  IPopSupportTabProductResource,
  IPopTechSpecsBlocks,
  IProductDetailPageContent,
  IProductOverviewPage,
  IProductOverviewPageContent,
} from '../interfaces/IProductOverviewPage';
import {
  TPopDefaultTechSpecs,
  TPopAccessoriesTab,
  TPopBuyNowTab,
  TPopCompatibleWith,
  TPopProductForumTab,
  TPopRatingsReviews,
  TPopSupportTab,
  TPopSupportTabProductResource,
  TPopTechSpecsBlock,
  TPopTechSpecsBlocks,
  TProductOverviewPage,
  TProductOverviewPageContent,
  TProductDetailPageContent,
  TPopSupportTabProductManual,
  TBuyNowCTAButton,
} from '../types/TProductOverviewPage';

export const ProductOverviewPageDTO = ({ content, product, fallback }: IProductOverviewPage): TProductOverviewPage => ({
  content: contentDTO(content, product),
  product: product,
  fallback: fallback,
});

export const ProductDetailPageDTO = ({ content, product }: IProductDetailPageContent): TProductDetailPageContent => {
  return {
    content: content && contentDTO(content, product),
    product: product,
  };
};

const contentDTO = (content: IProductOverviewPageContent, product: Product): TProductOverviewPageContent => ({
  accessories: content.accessories.map(accessoriesDTO),
  buyNow: content.buy_now ? buyNowDTO(content.buy_now[0]) : undefined,
  commercetoolsProduct: CtProductIDTO(content.commercetools_product),
  compatibleWith: content.compatible_with.map(compatibleWithDTO),
  fileName: content.file_name,
  footer: FooterDTO(content.footer_reference[0]),
  header: HeaderDTO(content.header_reference[0]),
  overviewTab: ContentBlocksDTO(content.overview_tab),
  pdpBrowTabsMenu: BrowDTO(content.pdp_brow_tabs_menu[0]),
  productForum: content.product_forum.map(productForumDTO),
  productSlug: content.product_slug,
  promoBanner: PromoBannerDTO(content.promo_banner_reference[0]),
  ratingsReviews: Boolean(content.ratings_reviews.length)
    ? ratingsReviewsDTO(content.ratings_reviews[0])
    : {
        defaultRatingsReviews: {
          displayDefaultBlock: false,
          uid: '',
        },
      },
  support: supportDTO(content.support),
  techSpecs: TechSpecsDTO(content.tech_specs, product),
  uid: content.uid,
});

const accessoriesDTO = ({ default_accessories }: IPopAccessoriesTab): TPopAccessoriesTab => ({
  defaultAccessories: {
    displayDefaultBlock: default_accessories.display_default_block,
    uid: default_accessories._metadata.uid,
  },
});

const buyNowCTAButtonDTO = (cta_button: IBuyNowCTAButton): TBuyNowCTAButton | undefined => {
  if (!cta_button.cta_button_copy_text || !cta_button.cta_button_url) {
    return undefined;
  }

  return {
    text: cta_button.cta_button_copy_text,
    url: cta_button.cta_button_url,
    openInNewWindow: cta_button.open_in_new_window,
  };
};

const buyNowDTO = (content: IPopBuyNowContent): TPopBuyNowTab | undefined => {
  const { default_buy_now } = content ?? {};

  if (!default_buy_now) {
    return undefined;
  }

  const { display_default_block, cta_button, _metadata } = default_buy_now;

  if (!display_default_block || !cta_button || !_metadata) {
    return undefined;
  }

  return {
    displayDefaultBlock: display_default_block,
    ctaButton: buyNowCTAButtonDTO(cta_button),
    uid: _metadata.uid,
  };
};

const compatibleWithDTO = ({ default_compatible_with }: IPopCompatibleWith): TPopCompatibleWith => ({
  defaultCompatibleWith: {
    displayDefaultBlock: default_compatible_with.display_default_block,
    uid: default_compatible_with._metadata.uid,
  },
});

const productForumDTO = ({ default_product_forum }: IPopProductForumTab): TPopProductForumTab => ({
  default_product_forum: {
    displayDefaultBlock: default_product_forum.display_default_block,
    uid: default_product_forum._metadata.uid,
  },
});

const ratingsReviewsDTO = ({ default_ratings_reviews }: IPopRatingsReviews): TPopRatingsReviews => ({
  defaultRatingsReviews: {
    displayDefaultBlock: default_ratings_reviews.display_default_block,
    uid: default_ratings_reviews._metadata.uid,
  },
});

const supportDTO = (supportBlocks: IPopSupportTabBlock[]): TPopSupportTab[] => {
  return supportBlocks.map(supportBlock);
};

const supportBlock = (supportBlock: IPopSupportTabBlock): TPopSupportTab => {
  const supportTab = supportBlock.default_support;

  return {
    displayDefaultBlock: supportTab.display_default_block,
    productResources: supportTab?.product_resources?.map(supportProductResourceDTO),
    productManual: supportTab?.product_manual?.map(supportProductManualDTO),
    uid: supportTab._metadata.uid,
  };
};

const supportProductResourceDTO = (productResource: IPopSupportTabProductResource): TPopSupportTabProductResource => ({
  resourceCopyText: productResource.resource_copy_text,
  resourceUrl: productResource.resource_url,
  uid: productResource._metadata.uid,
});

const supportProductManualDTO = (productManual: IPopSupportTabProductManual): TPopSupportTabProductManual => ({
  manualCopyText: productManual.manual_copy_text,
  manualUrl: productManual.manual_url,
  uid: productManual._metadata.uid,
});

const TechSpecsDTO = (data: IPopTechSpecsBlocks[], product: Product): TPopTechSpecsBlock => {
  return {
    contentTypeUid: 'tech_specs',
    techSpecsContentBlock: data.map((techContent) => TechSpecsContentBlockDTO(techContent, product)),
  };
};

const TechSpecsContentBlockDTO = (data: IPopTechSpecsBlocks, product: Product): TPopTechSpecsBlocks => {
  if (data.default_tech_specs) {
    return {
      defaultTechSpecs: {
        displayDefaultBlock: data.default_tech_specs.display_default_block,
        productSpecs: product.specs,
        uid: data.default_tech_specs._metadata.uid,
      } as unknown as TPopDefaultTechSpecs,
    };
  }

  if (data.one_to_four_column_block) {
    return {
      oneToFourColumn: OneToFourColumnDTO(data.one_to_four_column_block.one_to_four_column_reference?.[0]),
    };
  }

  if (data.full_width_text) {
    return {
      fullWidthText: FullWidthTextDTO(data.full_width_text.full_width_text_reference?.[0]),
    };
  }

  if (data.footnote_block) {
    return {
      footnote: FootNoteDTO(data.footnote_block.footnote_reference?.[0]),
    };
  }
  return {
    defaultTechSpecs: undefined,
  };
};
